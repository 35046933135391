.private-tour-container {
  display: flex;
  flex-direction: column;
}

.section {
  flex: 1;
  margin: 40px;
  padding: 4rem;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.section-content {
  display: flex;
  align-items: center;
  gap: 50px;
  height: 350px;
}

.custom-img {
  border-radius: 10px;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
  outline: 5px solid white;
  max-height: 360px;
  background-color: #f1f1f1;
}

.private-control {
  cursor: pointer;
}

.text-content h2 {
  font-size: 50px;
}

.text-content p {
  font-size: 18px;
}

.normal-bg {
  background-color: #f4f4f4;
}

.green-bg {
  background-color: #355e3bf5;
  color: #fff;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.private-card-container {
  display: flex;
  width: 90%;
  margin: 0 auto;
  gap: 50px;
}

.private-card {
  background-color: #fff;
  border-radius: 10px;
  text-align: left;
  min-width: 300px;
  width: 400px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.private-card h3 {
  font-size: 32px;
  font-weight: bold;
}

.private-card img {
  height: 180px;
  margin-bottom: 70px;
}

.section-title {
  font-size: 50px;
  margin: 0;
  transition: font-size 0.3s ease;
}

.section-title:hover {
  font-size: 55px;
}

@media (max-width: 768px) {
  .container-detail {
    margin-top: 30px;
  }

  .section {
    padding: 2rem;
    margin: 0;
  }

  .section-content {
    display: flex;
    flex-wrap: wrap;
    height: auto;
  }

  .text-content h2 {
    font-size: 24px;
  }

  .text-content p {
    font-size: 14px;
  }

  .custom-img {
    margin: 0 auto;
    max-height: 200px;
    max-width: 200px;
  }

  .private-card-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
  }

  .private-card img {
    display: flex;
    margin: 0 auto 50px;
  }

  .private-card {
    width: 330px;
    margin-bottom: 20px;
  }
}
