.add-tour-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.column-ul {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  gap: 20px;
}

.column {
  flex: 1;
}

.input-container {
  margin-bottom: 15px;
}

.input-field {
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}

.input-field.select-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}

.input-field-day {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
}

.add-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.delete-button {
  background-color: #f44336;
}

.highlight-container {
  margin-bottom: 15px;
}

.input-field-highlight {
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
}
