.admin-panel {
  padding: 20px;
  background-color: #f5f5f5;
}

.admin-panel h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.admin-panel button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.admin-panel button:hover {
  background-color: #0056b3;
}

.admin-panel .actions {
  margin-top: 20px;
}

.admin-panel .actions button {
  width: 100px;
  margin: 10px;
}

.admin-panel .logout-button {
  margin-top: 10px;
  background-color: #dc3545;
}

.admin-panel .logout-button:hover {
  background-color: #b02a37;
}

.login-form {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 15px;
}

.login-form h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.admin-panel-container {
  display: flex;
  justify-content: center;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  height: auto;
  width: 80%;
  margin-top: 15px;
  gap: 30px;
}

.delete-tour-container {
  width: 400px;
  margin: auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 50px;
}

.delete-tour-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.tour-select {
  width: 100%;
  cursor: pointer;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.delete-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.delete-button:hover {
  background-color: #d32f2f;
}

.quill-editor {
  height: 220px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow-y: auto;
}

.ql-container {
  border: none !important;
}

.ql-toolbar {
  border-radius: 5px;
}

/* DetailPage.css */

.detail-page-container,
.data-list-container {
  margin: 0 auto;
  padding: 20px;
  min-height: 500px;
  width: 75%;
  font-family: Arial, sans-serif;
}

.detail-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.data-details {
  margin-bottom: 20px;
}

.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.td-name {
  width: 250px;
}

.label {
  font-weight: bold;
  flex: 1;
  margin-right: 10px;
}

.data {
  flex: 4;
  word-break: break-all;
}

.ad-delete-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 10px;
  width: 100px;
  height: 50px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}

/* DataList.css */

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.data-table th,
.data-table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.data-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.data-row {
  transition: background-color 0.2s ease-in-out;
}

.data-row:hover {
  background-color: #b5b5b5;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 10px;
  border-radius: 5px;
  width: 100px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination span {
  font-size: 14px;
  margin: 0 20px;
  color: #333;
}
