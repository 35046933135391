.card-deck {
  display: flex;
  width: auto;
  justify-content: center;
}
.card-deck-container {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  width: 93%;
  padding: 2%;
}

.card-deck h5 {
  font-size: 26px;
}

.card-body p {
  font-size: 18px;
}

.card-img-top {
  min-height: 300px;
  max-height: 350px;
  object-fit: fill;
}

.card-body {
  flex-grow: 1;
}

.card-home {
  max-width: 400px;
  width: 380px;
}

.card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.btn-home {
  background-color: #355e3b;
  color: #fff;
  font-size: 20px;
  box-shadow: 0 1px 3px #355e3b;
  margin-bottom: 2%;
  margin-top: 2%;
  border: none;
  border-radius: 8px;
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.btn-home:hover {
  background-color: #fff;
  color: #355e3b;
  border: 1px solid #355e3b;
}

.more-tour {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 88%;
}

.more-tour h2 {
  font-size: 24px;
}

.btn-home-more {
  background-color: #355e3b;
  color: #fff;
  font-size: 20px;
  box-shadow: 0 1px 3px #355e3b;
  margin-bottom: 2%;
  margin-top: 2%;
  border: none;
  border-radius: 8px;
  width: 150px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  white-space: nowrap;
}
.btn-home-more:hover {
  background-color: #fff;
  color: #355e3b;
  border: 1px solid #355e3b;
}

.home-btn-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.home-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.home-cover img {
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
}

.time-zone {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.time-zone-box {
  text-align: center;
  padding: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
}

.zone-title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.zone-time {
  margin: 0;
  font-size: 22px;
}

@media (max-width: 1218px) {
  .card-deck {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
    width: auto;
  }
  .impression {
    min-width: 300px;
    padding: 2%;
    display: flex;
    flex-wrap: wrap;
  }

  .more-tour {
    display: flex;
    flex-direction: column;
  }

  .card-img-top {
    min-height: 250px;
    object-fit: fill;
  }

  .home-btn-container {
    display: flex;
    flex-wrap: wrap;
  }
}

.weather-detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.weather-city {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
}

.weather-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
  min-width: 180px;
  min-height: 255px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 10px;
  padding: 1%;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 93%;
  gap: 20px;
  padding: 1%;
}

.temp {
  font-weight: bold;
  font-size: 22px;
}

.weather-animation {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.weather-animation.show {
  opacity: 1;
}

.container-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 30px;
}

.img-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 270px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
  transition: transform 0.2s, filter 0.2s;
}

.img-container p {
  margin-top: 30px;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.rounded-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}

.btn-with-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-container:hover {
  transform: scale(1.1);
  filter: brightness(0.8);
}

.weather-detail p {
  font-size: 18px;
}
