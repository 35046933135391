.room-container-main {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.flex-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.room-content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
}

.room-content-form-container {
  display: flex;
  flex-direction: column;
}

.room-details-container {
  order: 1;
}

.map-img-container {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1%;
  padding-top: 0;
}

.big-image {
  width: 35%;
  max-height: 304px;
  height: auto;
  cursor: pointer;
  transition: filter 0.3s ease;
}

.big-image:hover {
  filter: brightness(80%);
}

.small-image {
  width: calc(33.3333% - 5px);
  height: 150px;
  object-fit: cover;
  cursor: pointer;
  transition: filter 0.3s ease;
}

.small-image:hover {
  filter: brightness(80%);
}

.images-container {
  display: flex;

  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.small-images-container {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  gap: 5px;
}

.content-room {
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  max-width: 900px;
  max-height: 900px;
  height: auto;
  box-shadow: 0 2px 4px rgba(0.1, 0.1, 0.1, 0.1);
  overflow: auto;
}

.content-room p {
  margin-bottom: 10px;
  line-height: 1.6;
}

.room-details li {
  margin-left: 5px;
  margin-bottom: 10px;
  line-height: 1.6;
  font-size: 14px;
}

.room-form {
  max-width: 1100px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 2%;
  margin-top: 0;
}

.form-control {
  width: 100%;
  max-width: 100%;
  outline: none;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #355e3b;
}

.form-group-room textarea {
  width: 100%;
  border-radius: 10px;
}

.room-details h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 25px;
}

.no-bullets {
  list-style: none;
  padding: 0;
}

.around-items {
  margin-top: 15px;
}

.around-items li {
  margin-bottom: 8px;
}

.room-icon {
  list-style: none;
  margin-right: 10px;
}

.room-details {
  font-size: 16px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}

.input-label {
  min-width: 85px;
  margin-right: 10px;
  text-align: start;
}

.maps {
  width: 300px;
  height: 250px;
}

.pin-location {
  font-size: 16px;
}

.room-highlight-container {
  display: flex;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;
  padding: 3%;
  height: auto;
  gap: 70px;
  justify-content: center;
  align-items: end;
}

.room-highlight {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.images-highlight {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  max-width: 50px;
  max-height: 50px;
}

.map-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(200, 200, 200);
  padding: 1%;
  border-radius: 10px;
  width: 320px;
  height: 305px;
}

.form-group-room {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .map-img-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 301px;
    margin-right: 0;
    border-radius: 10px;
    overflow: hidden;
  }

  .small-image {
    width: 97px;
    height: 100px;
    object-fit: cover;
    transition: filter 0.3s ease;
  }

  .big-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    cursor: pointer;
    transition: filter 0.3s ease;
  }

  .small-images-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: auto;
    margin: 0 auto;
    margin-left: 0;
    padding-top: 5px;
    gap: 5px;
  }

  .map-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(200, 200, 200);
    padding: 1%;
    border-radius: 10px;
    width: 301px;
    height: 350px;
    margin-bottom: 10px;
  }

  .content-room {
    margin: 0 auto;
    width: 300px;
  }

  .room-container-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .room-details-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .room-highlight {
    width: 70px;
    margin: 0 auto;
    display: flex;
    word-break: keep-all;
    text-align: center;
  }

  .room-highlight p {
    font-size: 14px;
  }

  .room-highlight-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 300px;
    gap: 20px !important;
    margin: 0 auto;
  }

  .room-form-container {
    width: 300px;
  }
}
