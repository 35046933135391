.form-container-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
  margin-top: 40px;
}

.form-container-title {
  padding: 2%;
  margin-bottom: 0;
  padding-bottom: 0;
}

.form-container-title h2 {
  font-size: 30px;
  font-weight: bold;
  padding: 2%;
  border-bottom: 1px solid rgb(187, 187, 187);
}

.form-sub-container {
  background-color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}

.form-sub-container-detail {
  display: flex;
  flex-direction: column;
}

.form-sub-container-detail p {
  font-weight: bold;
  font-size: 24px;
}

.form-content-container {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-around;
  margin: 2%;
  margin-bottom: 0;
  border-bottom: 1px solid rgb(187, 187, 187);
}

.form-content-list {
  margin-bottom: 0;
  padding: 1%;
  padding-top: 0;
}

.label-date,
.label-meal,
.label-price,
.label-guests,
.label-airlines {
  font-size: 20px;
}

.label-guests {
  font-size: 24px;
  font-weight: bold;
}

.ul-guests {
  margin: 4%;
  padding: 0;
}

@media (max-width: 1159px) {
  .form-container-main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-sub-container-detail {
    width: 100%;
    margin: 0;
  }

  .form-sub-container-detail p {
    font-weight: bold;
    font-size: 18px;
  }

  .form-sub-container-main {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .form-airways-logo-detail {
    object-fit: contain;
    height: 10px;
    width: 100px;
  }

  .form-input {
    margin: 2%;
  }

  .minus-button,
  .plus-button {
    background-color: #355e3b;
    color: #fff;
    border-radius: 15px;
    margin: 1%;
  }

  .form-container-title h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 2%;
  }
}

.form-content-button {
  padding: 0;
  margin: 0;
}

.form-airways-logo-detail {
  height: 100px;
  object-fit: contain;
  margin: 2%;
}

.form-detail {
  padding: 1%;
}

.form-detail p {
  font-size: 26px;
}
.form-detail span {
  font-size: 22px;
}

.guests-counter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.minus-button,
.plus-button {
  margin: auto;
  background-color: #355e3b;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 1%;
}

.minus-button:hover,
.plus-button:hover {
  background-color: #2e4131;
}

span.guests-number {
  margin-left: 15px;
  margin-right: 15px;
  width: 13px;
  height: auto;
  font-size: 24px;
  font-weight: bold;
}

.details-box {
  border: 2px solid #355e3b;
  border-radius: 5px;
  padding: 20px;
  width: 400px;
}

.details-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4%;
  border-bottom: 1px solid rgb(187, 187, 187);
}

.details-label {
  font-size: 24px;
}

.details-value {
  font-weight: bold;
  font-size: 24px;
}

.details-value p {
  font-weight: bold;
  font-size: 24px;
  margin: 0px;
}

.details-box-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2%;
  padding-top: 0;
  margin: 2%;
  border-bottom: 1px solid rgb(187, 187, 187);
}

.form-container {
  justify-content: center;
  align-items: center;
  width: auto;
}

.form-container ul {
  padding-left: 0;
  gap: 20px;
}

.form-group #email {
  margin-bottom: 2%;
}
.head-detail {
  padding: 2%;
}

.modal-body .label-data {
  font-size: 16px;
}

.modal-body p {
  font-weight: lighter;
  font-size: 22px;
  word-break: break-all;
}

.btn-detail-modal {
  background-color: #355e3b;
  color: #fff;
  box-shadow: 0 1px 3px #355e3b;
  border: #355e3b;
  border: 1px;
  border-radius: 8px;
  width: 80px;
  height: 40px;
}

.btn-detail-modal:hover {
  background-color: #406c47;
  color: #fff;
  border: #355e3b;
}

.btn-detail-modal-cancel {
  background-color: #dc3545;
  color: #fff;
  box-shadow: 0 1px 3px #dc3545;
  border: #dc3545;
  border: 1px;
  border-radius: 8px;
  width: 80px;
  height: 40px;
}

.btn-detail-modal-cancel:hover {
  background-color: #e73c4a;
  color: #fff;
  border: #dc3545;
}

.form-group {
  margin-bottom: 20px;
}

.container-form-detail ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.form-detail {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.form-group {
  flex: 1;
  margin: 0 10px;
}

.form-group label {
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 7px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.container-form-detail h3 {
  text-align: center;
  margin-top: 0;
  font-weight: bolder;
  font-size: 24px;
  margin-bottom: 20px;
}

.form {
  display: flex;
  justify-content: space-around;
  padding: 2%;
  padding-top: 0;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.btn-container-pdf {
  display: flex;
  align-items: center;
}

@media (max-width: 1130px) {
  .form-group {
    margin-bottom: 5px;
  }

  .container-form-detail {
    display: block;
  }

  .head-detail {
    padding: 2%;
  }

  .details-box-container p,
  span {
    font-size: 18px;
  }

  .plus-button,
  .minus-button {
    margin: auto;
  }
  .details-box-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .details-box-container p {
    font-size: 16px;
  }
  .details-box-container span {
    font-size: 16px;
  }

  .label-guests {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form {
    font-size: 14px;
  }

  .form-container ul {
    display: contents;
    padding-left: 0;
  }

  .form-group textarea {
    width: 100%;
    min-width: auto;
  }

  .head-detail {
    padding: 2%;
  }
}
