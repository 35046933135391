.carousel-inner {
  width: 500px;
  height: 370px;
  background-color: rgb(233, 233, 233);
  object-fit: contain;
  border-radius: 10px;
}

.container-carousel {
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 30px;
  margin: 20px;
  width: 90%;
  display: flex;
  border-radius: 10px;
  gap: 20px;
  flex-direction: row;
}

.carousel-item.active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-inner img {
  margin-left: 0;
  max-height: 400px;
  max-width: 700px;
  display: flex;
  justify-content: center;
}

.carousel-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-text p {
  margin: 0 auto;
  word-wrap: break-word;
  word-break: break-all;
  /* word-break: keep-all; */
  text-align: center;
  font-size: 16px;
}

.social-icons {
  position: fixed;
  bottom: 20px;
  left: 10px;
  z-index: 9999;
}

.social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 4px;
  text-decoration: none;
  font-size: 22px;
  flex-direction: column;
}

.social-icons p {
  font-size: 10px;
  margin: 0;
}

.social-icons a:last-child {
  margin-right: 0;
}

.social-icons a:hover {
  background-color: #eee;
}

.line {
  width: 50px;
  height: 50px;
}

.social-icons a:hover img {
  filter: brightness(80%);
}

.footer-container {
  background-color: #f0f0f0;

  text-align: center;
  margin-top: auto;
  width: 100%;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 25vh;
  padding-top: 50px;
}

.footer-text {
  margin: 0;
  color: #555;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-contact,
.footer-social,
.footer-links {
  flex: 1;
}

.footer-contact h4,
.footer-social h4,
.footer-links h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.footer-contact p,
.footer-social p,
.footer-links p {
  font-size: 14px;
  margin-bottom: 8px;
  max-width: 280px;
  margin: 0 auto;
}

.footer-social ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-social ul li {
  display: inline-block;
  margin-right: 15px;
}

.footer-social ul li:last-child {
  margin-right: 0;
}

.footer-social a {
  color: #333;
  font-size: 18px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links ul li {
  margin-bottom: 5px;
}

.footer-links a {
  color: #333;
  text-decoration: none;
}

.footer-bottom {
  text-align: center;
  margin-top: 20px;
}

.footer-bottom p {
  font-size: 14px;
}

.footer-bottom a {
  color: #333;
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}

.footer-social-icons {
  display: flex;
  justify-content: center;

  bottom: 20px;
  left: 10px;
  z-index: 9999;
}

.footer-social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 4px;
  text-decoration: none;
  font-size: 22px;
  flex-direction: column;
}

.footer-social-icons p {
  font-size: 10px;
  margin: 0;
}

.footer-social-icons a:last-child {
  margin-right: 0;
}

.footer-social-icons a:hover {
  background-color: #cacaca;
}

.line {
  width: 50px;
  height: 50px;
}

.footer-social-icons a:hover img {
  filter: brightness(80%);
}

.admin-login-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.button-admin-panel {
  padding: 10px 20px;

  background-color: #007bff;
  color: #fff;
  border: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.breadcrumb-container {
  display: flex;
  margin-bottom: 10px;
}

.breadcrumb-link {
  font-size: 18px;
  font-weight: 700;
  color: #555555;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.breadcrumb-link.active {
  color: #249739;
}

.breadcrumb-link:hover {
  color: #249739;
}

.breadcrumb-separator {
  color: #646464;
  margin: 0 5px;
}

.search-form {
  display: flex;
  flex-direction: row;
  height: 220px;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  color: #333333;
  width: auto;
  max-width: 1200px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-label {
  font-weight: bold;
  color: #555555;
}

.search-select,
.search-input {
  padding: 8px;
  height: 40px;
  width: 180px;
  border: none;
  border-radius: 10px;
  outline: none;
  border-color: rgb(67, 80, 98);
  background-color: #e0e0e0;
}

.search-input:focus {
  outline: none;

  border: 1px solid rgb(169, 169, 169);
}

.search-button {
  padding: 8px 15px;
  background-color: #33961f;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #3baf24;
}

.time-weather-container {
  display: flex;
  gap: 20px;
}

@media (max-width: 1222px) {
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
  }

  .search-form {
    flex-direction: column;
    height: auto;
    padding: 10px;
    max-width: none;
    width: 320px;
  }

  .search-input,
  .search-select,
  .search-button {
    width: 100%;
    margin-bottom: 10px;
  }
  .header-container {
    display: flex;
    flex-direction: column;
  }
  .time-weather-container {
    display: flex;
    gap: 20px;
  }
  .search-group {
    width: 100%;
  }
  .footer-contact,
  .footer-social,
  .footer-links {
    margin-bottom: 20px;
  }

  .carousel {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container-carousel {
    display: flex;
    flex-direction: column;
  }
}

.testimonial-container h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #222;
  padding: 22px;
}

.navbar-toggler-icon {
  border: none;
}

.navbar-toggler {
  border: none;
  padding: 0.25rem 0.5rem;
  background-color: transparent;
  order: 1;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

@media (max-width: 990px) {
  .navbar-nav {
    display: flex;
    margin: 15px;
    align-items: start;
    gap: 5px !important;
  }
  .navbar {
    display: flex !important;
    align-items: start !important;
    flex-grow: 1;
    justify-content: start !important;
  }
  .nav-item {
    border-bottom: 0.2px solid;
    width: 100%;
  }

  .navbar-toggler {
    text-decoration: none;
    margin-left: 10px;
  }

  .social-icons {
    display: none;
  }
}
