.car-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.car-card {
  display: flex;
  align-items: center;
  min-width: 900px;
  max-width: 1200px;
  height: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 3%;
  border-radius: 5px;
}

.car-image {
  width: 150px;
  height: 100px;
  object-fit: contain;
  margin-right: 30px;
}

.car-details {
  display: flex;
  flex-direction: column;
  /* padding: 2%; */
  padding-left: 50px;
  border-left: 1px solid rgb(187, 187, 187);
}

h1 {
  margin-bottom: 20px;
  justify-content: center;
}

h2 {
  margin-bottom: 10px;
  font-size: 28px;
}

p {
  margin: 5px 0;
  font-size: 20px;
}

.car-card {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s, box-shadow 0.3s;
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.car-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.car-img {
  width: 300px;
  height: 300px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .car-card {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 10px;
    width: 270px;
    height: 350px;
    min-width: 0;
  }

  .car-image {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    border-bottom: 1px solid rgb(187, 187, 187);
    margin-bottom: 20px;
  }

  .car-details {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    padding: 2 !important;
    padding-left: 0;
  }
  .car-form-content-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 150px;
  }
}
