.container-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;
  margin-top: 40px;
}

.container-title {
  border-bottom: 1px solid rgb(187, 187, 187);
  word-break: break-all;
  margin-bottom: 20px;
}

.container-title h2 {
  font-size: 36px;
}

.header-detail {
  display: flex;
  justify-content: space-between;
}

.image-container {
  width: 700px;
  height: 400px;
  overflow: hidden;
  border-radius: 1rem;
  background-color: #fff;
}

.image-container img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  object-fit: contain;
}

.sub-container-1 {
  background-color: #fff;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}

.sub-container-main {
  display: flex;
  gap: 10px;
}

.sub-container-content {
  display: flex;
  justify-content: center;

  width: 100%;
}

.sub-container-detail-1 {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgb(187, 187, 187);
  margin: 3%;
}
.sub-container-detail-1 p {
  font-weight: bold;
  font-size: 22px;
}

.sub-container-detail-2 {
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;
}

.sub-container-detail-2 p {
  padding: 1%;
  padding-bottom: 0;
  margin-bottom: 5px;
  word-break: keep-all;
  word-wrap: break-word;
}

.content-container {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
}

.label-price,
.label-date,
.label-airlines {
  display: flex;
  align-items: center;
}

.content-list {
  margin-bottom: 0;
  padding-left: 3%;
}

.label-airlines p {
  display: flex;
  align-items: center;
}

.airways-logo-detail {
  object-fit: contain;
  display: flex;
  margin: auto;
  height: 70px;
  width: auto;
}

.content-button {
  padding-right: 3%;
}

.btn-detail-tour {
  width: 100%;
  height: 40px;
  background-color: #355e3b;
  color: #fff;
  border: #355e3b;
  border-radius: 10px;
  font-size: 20px;
  box-shadow: 0 1px 2px #355e3b;
  transition: background-color 0.2s, color 0.3s, box-shadow 0.3s;
}

.btn-detail-tour:hover {
  background-color: #fff;
  color: #355e3b;
}

.label-highlight {
  padding-left: 2%;
  font-weight: bold;
  font-size: 24px;
}

.detail-tour {
  background-color: #fff;
  margin-top: 2%;
  border-radius: 1rem;
  width: 75%;
  height: 100%;
}

.title-detail {
  padding: 10px;
  font-weight: bold;
  font-size: 24px;
}

@media (max-width: 1159px) {
  .content-button {
    flex-direction: row;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.timeline-morning-description,
.timeline-midday-description,
.timeline-evening-description p {
  margin-bottom: 2%;
  word-break: keep-all;
  word-wrap: break-word;
}

.timeline-period {
  margin-bottom: 2%;
  /* word-break: keep-all; */
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.timeline-date {
  font-weight: bold;
  font-size: 18px;
  background-color: #355e3b;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.detail-tour {
  padding: 2%;
}

.timeline-description {
  font-size: 18px;
}

.btn-detail-container {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 0;
}

.btn-detail-pdf {
  height: auto;
  width: auto;
  border-radius: 10px;
  background-color: #355e3b;
  color: #fff;
  border: #355e3b;
  padding: 10px;
  box-shadow: 0px 1px 2px #355e3b;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s,
    box-shadow 0.3s;
}

.btn-detail-pdf:hover {
  background-color: #ffffff;
  color: #355e3b;
  box-shadow: 0px 1px 2px #355e3b;
}

.timeline-left {
  max-height: 250px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .container-main {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sub-container-detail-1 {
    width: 100%;
    margin: 0;
  }

  .sub-container-detail-1 p {
    font-weight: bold;
    font-size: 18px;
  }

  .image-container {
    width: auto;
    height: 250px;
  }

  .sub-container-main {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .sub-container-1 {
    margin-top: 4%;
  }

  .airways-logo-detail {
    height: auto;
    width: 80px;
  }

  .content-container {
    margin: 3%;
  }

  .content-button button {
    justify-content: center;
    width: 100%;
  }

  .content-button {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .img {
    align-items: center;
    justify-content: center;
    padding-bottom: 0px;
  }

  .container-title h2 {
    font-size: 20px;
  }

  .timeline-date {
    font-size: 14px;
    width: 45px;
    height: 45px;
  }

  .btn-detail-pdf {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
  }
}
