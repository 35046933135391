.room-container {
  margin: 0 auto;
}

.room-card {
  max-width: 1200px;

  padding-top: 0;
  border: 1px solid #ccc;
  margin-bottom: 3%;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s, box-shadow 0.3s;
  background: #fff;
  cursor: pointer;
}

.room-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.image-container {
  position: relative;
  overflow: visible;
}

.room-big-images-container {
  position: relative;
  justify-content: center;
}

.room-big-image {
  width: 392px;
  height: 310px;
  object-fit: cover;
  margin-bottom: 2px;
}

.room-details-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

h1 {
  justify-content: center;
}

h2 {
  font-size: 28px;
  margin-bottom: 0;
}

.room-small-image {
  width: 76px;
  height: 65px;
}

.room-small-images {
  display: flex;
  align-items: center;
  gap: 3px;
}

.star-container {
  display: flex;
  align-items: center;
}

.star-container svg {
  width: 22px;
  height: 22px;
  margin-bottom: 2px;
}

.feature-box {
  display: flex;
  align-items: center;
  width: auto;
  justify-content: start;
  padding: 2px;
  margin-right: 10px;
}

.icon {
  margin-right: 4px;
}

.text {
  margin-right: 15px;
}

.room-header {
  display: flex;
  justify-content: start;
  align-items: start;
}

.container-card {
  display: flex;
  gap: 50px;
}

@media (max-width: 768px) {
  .room-card {
    max-width: 320px;
    margin: 10px 0;
  }

  .room-big-image {
    max-width: 320px;
  }

  .room-details-card {
    padding: 10px;
  }

  .room-small-image {
    width: 61.2px;
    height: 60px;
  }

  h2 {
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  .container-card {
    display: flex;
    flex-direction: column;
  }
}
