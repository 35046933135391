.car-form-content-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-bottom: 20px;
}

.car-form-content-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin: 3%;
  border-bottom: 1px solid rgb(187, 187, 187);
  border-top: 1px solid rgb(187, 187, 187);
}

.car-img {
  width: 300px;
  height: 300px;
}

.label-detail {
  display: flex;
  flex-wrap: wrap;
  margin-right: 100px;
}

.cursor-pointer select {
  cursor: pointer;
}

.car-input-label {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: auto;
  margin-right: 10px;
  text-align: start;
}

.car-form-container-title h2 {
  margin: 20px;
  padding: 2%;
  padding-bottom: 0;
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .car-form-content-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0px;
  }

  .car-form-content-list {
    display: block;
  }

  .sub-container-main h2 {
    font-size: 22px;
  }
}
