.image-enlargement-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.image-enlargement-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.image-enlargement-content img {
  height: auto;
  display: block;
  object-fit: contain;
  max-height: 700px;
}

.image-enlargement-content button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 12px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
