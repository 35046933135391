.container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 40px;
  width: 85%;
}

.card-container {
  flex-wrap: wrap;
  flex-direction: column;
  display: flex;
  justify-content: center;
  max-width: 1200px;
}

.card-header {
  display: flex;
}

.card-content {
  display: flex;
  flex-direction: row;
  max-width: 1000px;
  min-height: 380px;
}

.card-img-container {
  max-width: 380px;
  max-height: 380px;
  overflow: hidden;
  position: relative;
}

.card-img {
  object-position: top;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-left: 2%;
  padding-right: 2%;
  overflow: auto;
}

.card-title {
  margin: 2%;
  font-weight: bold;
  font-size: 30px;
}

.card-description {
  min-height: 160px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  border-top: 1px solid rgb(187, 187, 187);
  overflow-y: auto;
  padding: 1%;
}

.card-description p {
  font-size: 16px;
}

.card-price {
  height: 50px;
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
}

.card {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s, box-shadow 0.3s;
}

.text-date {
  font-size: large;
}

.bold-numbers {
  font-weight: 600;
}

.container-content {
  border-bottom: 1px solid rgb(187, 187, 187);
  width: 100%;
}

.btn-detail {
  font-size: 20px;
  margin-bottom: 2%;
  margin-top: 2%;
  box-shadow: 1px 1px 3px #355e3b;
  border-radius: 8px;
  width: 220px;
  height: 40px;
  background-color: #355e3b;
  color: #fff;
  border: #355e3b;
  transition: background-color 0.2s, color 0.3s, box-shadow 0.3s;
}

.btn-detail:hover {
  background-color: #fff;
  color: #355e3b;
  border: #355e3b;
  box-shadow: 1px 1px 3px #355e3b;
}

.container-detail {
  width: 75%;
  margin: 0 auto;
}

.additional {
  display: flex;

  padding: 2%;
  align-items: center;
  gap: 5px;
}

.additional .text-date {
  padding-left: 2%;
  padding-right: 2%;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  margin: 0;
  height: 50px;
}

.airways-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 70px;
}

.airways-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.custom-input {
  background-color: #f2f2f2 !important;
  outline: none;
  border-color: #ccc;
}

.custom-input:focus {
  background-color: #f2f2f2;
  outline: none;
  box-shadow: none;
  border-color: #ccc;
}

.card-content-link {
  color: black;
  text-decoration: none;
}

.card-content-link:hover {
  color: black;
  text-decoration: none;
}

.button-view-more {
  background-color: #355e3b;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.button-view-more:hover {
  background-color: rgb(43, 85, 59);
}
.container-button-view-more {
  display: flex;
  justify-content: center;
}

@media (max-width: 991px) {
  .card {
    width: 100%;
    max-width: 300px;
  }

  .card-content {
    flex-direction: column;
  }

  .card-img {
    padding-bottom: 2%;
    height: auto;
  }

  .card-img-container {
    order: -1;
    max-height: 350px;
    width: 100%;
  }

  .card-title {
    font-size: 20px;
    word-break: keep-all;
  }

  .card-header {
    display: flex;
    width: auto;
    /* margin-left: 30px; */
  }

  .logo-container {
    overflow: hidden;
  }

  .container-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .btn-detail {
    width: 100%;
  }

  .card-description {
    padding: 10px;
    word-break: keep-all;
    overflow-y: auto;
    max-height: 100px;
  }

  .card-description p,
  strong {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  margin: 0 auto;
}

.loader-center {
  text-align: center;
}
