.custom-datepicker-header select {
  cursor: pointer;
  font-size: 14px;
  margin: 3px;
  padding: 5px;
  border-radius: 5px;
}

.custom-datepicker-header {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
}

.custom-datepicker-wrapper {
  display: flex;
  width: 100%;
}

.react-datepicker__input-container {
  margin-right: 70px;
}
