@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");
@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css");

body {
  background-color: #f5f5f5;
  font-family: "Kanit", sans-serif;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.header-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.nav-link {
  display: flex;
  align-items: center;
}

.navbar-nav {
  gap: 70px;
}

.nav-item {
  font-size: 16px;
  padding: 1px;
}

.custom-sweetalert {
  text-align: center;
}

.custom-sweetalert .swal2-title {
  font-size: 24px;
  color: #007bff;
}

.custom-sweetalert .swal2-content {
  font-size: 18px;
  color: #333;
}

.label-data {
  display: inline-block;
  width: 120px;

  margin-right: 10px;
  text-align: left;
}

.modal-container p {
  margin-bottom: 10px;
}

@keyframes wipe-in-left-animation {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.wipe-in-left {
  animation: 2s cubic-bezier(0.25, 1, 0.3, 1) both wipe-in-left-animation;
}

@keyframes wipe-in-right-animation {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.wipe-in-right {
  animation: 2s cubic-bezier(0.25, 1, 0.3, 1) both wipe-in-right-animation;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.card-animation {
  animation: 1s ease-in-out both slide-in;
}

.fade-in {
  animation: fadeIn 2s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.breadcrumb-container span {
  font-size: 14px;
  display: flex;
}

p {
  word-break: keep-all;
}
